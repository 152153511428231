import React, {useEffect} from 'react';
import {EmailIcon, LinkedinIcon, TwitterIcon} from "../icons";
import {isMobile} from "react-device-detect";
import cx from "classnames";
import {addTimeout} from '../utils/timeManager';

const AuthorDetails = ({author, className, deferImage, nameClass, bioClass}) => {
    className = cx(
        "",
        className
    );
    nameClass = cx(
        nameClass || "font-serif text-xl lg:text-3xl mr-4"
    );
    bioClass = cx(
        bioClass || "text-lg leading-snug my-4"
    );

    useEffect(() => {
        if(!deferImage) {
            return;
        }
        addTimeout(() => {
            const img = document.getElementById('authorDetailsImage');
            if (img && img.getAttribute('data-src')) {
                img.setAttribute('src', img.getAttribute('data-src'));
            }
        }, 500 * (isMobile ? 4 : 1));
    }, [deferImage]);

    return (
        <div className={className} data-datocms-noindex>
            <div className="mb-4" style={{maxWidth: "128px"}}>
                {deferImage && <amp-img class="rounded-full align-middle" id={"authorDetailsImage"} width={128} height={128} alt={author.name} layout="responsive"
                                          src={author.profilePhoto && author.profilePhoto.url ? `${author.profilePhoto.url}&w=128&h=128&fit=crop` : `/images/logos/acronym.png`}/>}
                {!deferImage && <amp-img class="rounded-full align-middle" width={128} height={128} alt={author.name} layout="responsive"
                                          src={author.profilePhoto && author.profilePhoto.url ? `${author.profilePhoto.url}&w=128&h=128&fit=crop` : `/images/logos/acronym.png`}/>}
            </div>
            <div className="flex">
                <h2 className={nameClass}>{author.name}</h2>
                <div className="flex social-container">
                    {author.linkedin && <a href={author.linkedin} target="_blank" rel="noopener noreferrer" className="mr-2">
                        <LinkedinIcon iconFillColor={'white'} size={32} round={true}/>
                    </a>}
                    {author.email && <a href={`mailto:${author.email}`} target="_blank" rel="noopener noreferrer" className="mr-2">
                        <EmailIcon iconFillColor={'white'} size={32} round={true}/>
                    </a>}
                    {author.twitter && <a href={author.twitter} target="_blank" rel="noopener noreferrer">
                        <TwitterIcon iconFillColor={'white'} size={32} round={true}/>
                    </a>}
                </div>
            </div>
            <div className={bioClass} dangerouslySetInnerHTML={{__html: author.bio}}/>
        </div>
    )
}

export default AuthorDetails
